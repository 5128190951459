import { useOrgTheme } from "../../context/ThemeContext";

export default function CopyRightNote() {
  const { getOrgName } = useOrgTheme();
  const orgName = getOrgName();
  const currentYear = new Date().getFullYear();
  return (
    <>{orgName && <span className="">&#169; {currentYear} {orgName}. All rights reserved</span>}</>
  );
}


import dynamic from "next/dynamic";
import { HomePageContent } from "../../../pages";

const TrustedPartner = dynamic(() => import("../../Common/TrustedPartner"));
const ClientBanner = dynamic(() => import("./Banner"));
const ClientSection = dynamic(() => import("./Section"));


const ClientOrgHome = ({ content }: { content: HomePageContent }) => {
  return (
    <>
      <ClientBanner content={content.homePageContent} />
      <TrustedPartner />
      <ClientSection htmlContent={content.htmlContent} />
    </>
  );
};

export default ClientOrgHome;

import React from "react";
import GetEstimateForm from "../EstimateForm";

const SlideBanner = () => {

  return (
    <section className="xxs:-mb-0 w-100 place-center home home-banner shopnow-landing 12 "
      id="quote-form">
      <div className="bg-center bg-no-repeat bg-cover bg-hero min-h-screen">
        <div className="px-4 xxs:px-6 md:px-8 xl:p-4 md:container md:m-auto">
          <div className="flex flex-col py-6 sm:py-8 md:pb-16 md:pt-6 place-center items-center justify-start xs380:justify-center max-w-4xl xd:max-w-5xl m-auto h-screen-90">
            <div className="grid auto-rows-auto place-self-center md:text-center">
              <h3 className="text-2xl xxs:text-3xl xl:text-4xl xd:text-5xl font-sfLight text-white mb-1">Experience The Future Of Solar:</h3>
              <h1 className="text-cus-3xl xs380:text-4xxxl xxs:text-4xl xl:text-5xl xd:text-6xl xl:leading-tight xd:leading-tight font-sfBold text-white mb-2 xxs:mb-5 md:mb-6">
                Buying Online has never been Simpler with our&nbsp;<span className="text-primary inline xxs:block md:inline">AI-Powered Tool</span>
              </h1>
              <p className="text-white text-lg leading-6 xxs:text-2xl xd:text-3xl lg:px-12 xd:px-10 mb-6 xxs:mb-5 md:mb-6 lg:mb-8">
                Purchase online with Solar Quote's <span className="font-sfBold">AI-powered platform</span> today and take advantage of our <span className="font-sfBold">UNBEATABLE</span> prices.
                We are committed to making sustainable power <span className="border-b">accessible to all!</span></p>

              <div className="w-full max-w-3xl xd:max-w-4xl mx-auto">
                <GetEstimateForm layout="slider" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SlideBanner;
import Link from "next/link";
import React, { useContext} from "react";
import { useRouter } from "next/router";
import { UserContext } from "../components/UserContext";
import PrivacyPolicyRoute from "./Common/PrivacyPolicyRoute";
import CopyRightNote from "./Common/CopyrightNote";
const Footer = () => {
  const {isStateNewJersey} = useContext(UserContext);

  const route = useRouter();
 
  route.pathname === "/blog/[slug]"
  

  return (
    <footer className="shadow-md px-4 md:px-8 py-8 lg:py-12 xl:py-16 bg-gray-50">
      <div className="lg:container flex flex-col items-start justify-start lg:m-auto">
       <div className="w-full flex md:flex-row flex-col-reverse items-start justify-start">
        <div className="md:w-2/6 2md:w-4/12 flex flex-col xxs:items-start justify-center md:flex-col md:items-start mx-auto md:mx-0 text-center md:text-left max-w-420">
        {!isStateNewJersey && (<Link href='/'>
          <a className="flex justify-center md:justify-start">
            <img
              src="/images/logo.png"
              width="245"
              className="w-36 xxs:w-44 2md:w-60 2md:px-5"
            />
          </a>
          </Link>)}
          <p className="text-xs xxs:text-sm 2md:text-base px-1 2md:px-6 pb-1 pt-4 text-bodyColor font-primaryMedium xxs:w-4/5">
            <CopyRightNote/> &nbsp;
            <span className="block md:inline-flex">
              <PrivacyPolicyRoute />
              &nbsp;
              <Link href="tel:385-367-5974">
                <a className="text-titleColor hover:underline font-primaryMedium cursor-pointer">
                  385-367-5974
                </a>
              </Link>
            </span>
          </p>
          {isStateNewJersey && (<Link href='/'>
          <a>
          <img
              src="/images/logo-suntiuity.png"
              // width="245"
              className="w-32 xxs:w-40 2md:w-56 2md:px-5"
            />
            </a>
            </Link>)}
        </div>
        <div className="w-full md:w-4/6 2md:w-8/12 mb-6 md:mb-0">
          <div className="grid gap-x-3 gap-y-5 xxs:gap-5 xxs:grid-cols-4 grid-cols-3 2md:pl-8">
            <div className="grid gap-4 place-content-start xxs:items-center xxs:mx-auto">
              <h6 className="text-xs xxs:text-sm 2md:text-base font-primaryBold text-titleColor uppercase">
                About
              </h6>
              <div className="grid gap-2 text-bodyColor font-primaryMedium">
              <Link href='/'>
                  <a className="hover:text-titleColor cursor-pointer text-sm 2md:text-base">
                    {" "}
                    Home
                  </a>
                </Link>
                <Link href="/company">
                  <a className="hover:text-titleColor cursor-pointer text-sm 2md:text-base">
                    Company
                  </a>
                </Link>
                <Link href="/whySolar">
                  <a className="hover:text-titleColor cursor-pointer text-sm 2md:text-base">
                    {" "}
                    Why Solar
                  </a>
                </Link>
              </div>
            </div>

            <div className="grid gap-4 place-content-start xxs:items-center xxs:mx-auto">
              <h6 className="text-xs xxs:text-sm 2md:text-base font-primaryBold text-titleColor uppercase">
                Products
              </h6>
              <div className="grid gap-2 text-bodyColor font-primaryMedium">
                <Link href="/solar">
                  <a className="hover:text-titleColor cursor-pointer text-sm 2md:text-base">
                    Solar
                  </a>
                </Link>
                <Link href="/battery">
                  <a className="hover:text-titleColor cursor-pointer text-sm 2md:text-base">
                    Battery
                  </a>
                </Link>
                {/* <Link href="/charger"><span className="hover:text-titleColor cursor-pointer text-sm 2md:text-base"> EV Chargers</span></Link> */}
              </div>
            </div>

            <div className="grid gap-4 place-content-start xxs:items-center xxs:mx-auto">
              <h6 className="text-xs xxs:text-sm 2md:text-base font-primaryBold text-titleColor uppercase">
                Help
              </h6>
              <div className="grid gap-2 text-bodyColor font-primaryMedium">
                {/* <Link href="/get-estimate"><span className="hover:text-titleColor cursor-pointer text-sm 2md:text-base">Get Estimate</span></Link> */}
                <Link href="/support">
                  <a className="hover:text-titleColor cursor-pointer text-sm 2md:text-base">
                    Support
                  </a>
                </Link>
                <PrivacyPolicyRoute className={"hover:text-titleColor cursor-pointer text-sm 2md:text-base"} />
                
              </div>
            </div>

            <div className="flex col-span-4 xxs:space-x-0 space-x-4 xxs:col-span-1 xxs:grid xxs:gap-4 place-content-start items-center xxs:items-center xxs:mx-auto">
              <h6 className="text-xs xxs:text-sm 2md:text-base font-primaryBold text-titleColor">
                Follow Us
              </h6>
              <div className="grid gap-2 text-bodyColor font-primaryMedium grid-cols-4">
                <Link href="https://www.facebook.com/Solar-Quote-103110515181282">
                <a target="_blank" rel="noreferrer">
                {route.pathname === "/blog/[slug]" || route.pathname === "/resources/[slug]" ?
                      <img
                        src="../images/facebook-icon.png"
                        className="hover:opacity-90 md:w-8 md:h-8 h-6 w-6 cursor-pointer"
                        alt="social-icon"
                      />
                      :
                      <img
                        src="/images/facebook-icon.png"
                        className="hover:opacity-90 md:w-8 md:h-8 h-6 w-6 cursor-pointer"
                        alt="social-icon"
                      />
                    }
                  </a>
                </Link>
                <Link href="https://www.instagram.com/solarquoteinc">
                <a target="_blank" rel="noreferrer">
                    {route.pathname === "/blog/[slug]" || route.pathname === "/resources/[slug]" ?
                      <img
                        src="../images/instagram-icon.png"
                        className="hover:opacity-90 md:w-8 md:h-8 h-6 w-6 cursor-pointer"
                        alt="social-icon"
                      />
                      :
                      <img
                        src="/images/instagram-icon.png"
                        className="hover:opacity-90 md:w-8 md:h-8 h-6 w-6 cursor-pointer"
                        alt="social-icon"
                      />
                    }
                  </a>
                </Link>
                <Link href="https://www.linkedin.com/company/solarquote/">
                <a target="_blank" rel="noreferrer">
                    {route.pathname === "/blog/[slug]" || route.pathname === "/resources/[slug]" ?
                      <img
                        src="../images/linkedin.png"
                        className="hover:opacity-90 md:w-8 md:h-8 h-6 w-6 cursor-pointer"
                        alt="social-icon"
                      />
                      :
                      <img
                        src="/images/linkedin.png"
                        className="hover:opacity-90 md:w-8 md:h-8 h-6 w-6 cursor-pointer"
                        alt="social-icon"
                      />
                    }
                  </a>
                </Link>
                {/* <a target="_blank" rel="noreferrer" href="#">
                    {route.pathname === "/blog/[slug]" || route.pathname === "/resources/[slug]" ?
                      <img
                        src="../images/tiktok.png"
                        className="hover:opacity-90 md:w-8 md:h-8 h-6 w-6 cursor-pointer"
                        alt="social-icon"
                      />
                      :
                      <img
                        src="./images/tiktok.png"
                        className="hover:opacity-90 md:w-8 md:h-8 h-6 w-6 cursor-pointer"
                        alt="social-icon"
                      />
                    }
                  </a> */}
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="w-full">
          <p className="text-xs xxs:text-sm px-2 pt-4 md:pt-7 pb-3 opacity-80 text-graylight450 text-center font-primaryMedium max-w-1210 m-auto">
          AL-45084, AZ-ROC353458, CA-1091448, CT-HIC is 0698656 ELC.0204430-E1, FL-EC13009912, GA, IL-24-0065, IA-EL205493MA, KS-L22ELE-0098, 
          ME-MS60021127, MA-8734-EL-A1, MI-262400048, MN-BC808202, NH-13335, NJ-13 VH12924200, NC-U.33076, OK-200130, PA-PA189596, TX-391209, 37203, 
          WI-1512074, MT-ELE-EM-LIC-43606 lic.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import { Fade } from "./../../components/Animation";
import Carousel from "react-multi-carousel";
import { useContext } from "react";
import ThemeContext from "../../context/ThemeContext";
import { isMobile } from "../DeviceType";

const responsive = {
  Desktop: {
    breakpoint: { max: 4000, min: 1025 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 641 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 1,
    partialVisibilityGutter: 80,
  },
};

const TrustedPartner = () => {
  const { getTrustedPartners } = useContext(ThemeContext);
  const data = getTrustedPartners();
  if(!data?.length){
    return <section></section>
  }
  return (
    <section className="relative xxs:px-6 bg-white py-6 px-4 md:px-8 xl:px-40 our-partner-section w-full">
      <div className="xl:p-6 p-4 rounded bg-white section-wrapper">
        <h1 className="text-titleColor text-center text-3xl xd:text-4xl tracking-wide font-sfBold pb-4 xxs:ml-6">
          Our <span className="text-primary"> Trusted Partners</span>
        </h1>
        <div className="max-w-screen-md- max-w-5xl m-auto w-full pb-4">
          <div className="grid grid-cols-1 gap-4 xl:gap-6">
            <Carousel
              className="mx-2 partner-carousel"
              responsive={responsive}
              showDots={isMobile}
              arrows={false}
              partialVisible
            >
              {data.map((item: any) => {
                const { imageUrl, imageName } = item;
                return (
                  <Fade triggerOnce delay={500} duration={1500}>
                    <div className="grid place-items-center">
                      <img
                        src={imageUrl}
                        alt={imageName}
                        width="136"
                        className="object-contain image-filter"
                      />
                    </div>
                  </Fade>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};
export default TrustedPartner;

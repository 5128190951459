import "animate.css";
import React, { memo, useEffect, useMemo } from "react";
import { NextPageContext } from "next";
import "react-toastify/dist/ReactToastify.css";
import { useOrgTheme } from "../context/ThemeContext";
import { getPageContent } from "../utils/contentFetcher";
import { OrganisationTheme } from "../interfaces/Theme";
import { AffiliateContent } from "./ambassador";
import Affiliate from "../components/Affiliate";
import OrgProgramTypeHomePage from "../components/Home/OrgProgramTypeHomePage";

export type HomePageContent = Pick<
  OrganisationTheme,
  | "htmlContent"
  | "cssVariables"
  | "trustedPartners"
  | "orgId"
  | "hostNameId"
  |"is_preview"
  |"orgName"
  | "isAmbassadorProgram"
> & {
  isClient: boolean;
  homePageContent: OrganisationTheme["pageContent"]["homepageContent"];
};

const IndexPage = ({ content }: { content: any}) => {
  const {sethostname } = useOrgTheme();

  const homeContent = useMemo(() => {
    if (content?.orgId) {
      return content;
    }
  }, [content]);

  useEffect(() => {
    if (homeContent?.orgId) {
      sethostname({
        id: homeContent.hostNameId,
        orgId: homeContent.orgId,
        preview: homeContent.is_preview,
      });
    }
    if(homeContent?.isAmbassadorProgram){
      homeContent['affiliatePageContent'] = homeContent['homePageContent'];
    }
  }, [homeContent]);

  return !!homeContent?.isAmbassadorProgram ? (
    <Affiliate content={homeContent as AffiliateContent} />
  ) : (
    <OrgProgramTypeHomePage content={homeContent as HomePageContent } />
  );
};

export default memo(IndexPage);

export async function getServerSideProps(ctx: NextPageContext) {
  const res = await getPageContent("home", ctx);
  return {
    props: {
      content: res.data,
    },
  };
}

import React, { memo, useCallback, useMemo } from "react";
import Layout from "../Layout";
import ClientOrgHome from "./Client";
import Home from ".";
import { ToastContainer } from "react-toastify";
import { useOrgTheme } from "../../context/ThemeContext";
import { getMultiTenentMeta } from "../../utils/meta";
import { HomePageContent } from "../../pages";

const OrgProgramTypeHomePage = memo(({ content }: { content: HomePageContent }) => {
  const { isClientOrg, getOrgSlug, } = useOrgTheme();

  const getHomeContent = useCallback(() => {
    if (isClientOrg) {
      return <ClientOrgHome content={content} />;
    } else if (getOrgSlug() === "solarquote") {
      return <Home />;
    }
    return null;
  }, [content, isClientOrg]);

  const orgName = content?.orgName || "";
  const MemoizedHomeContent = useMemo(() => memo(getHomeContent),[getHomeContent]);
  const indexPageMetaTagsContent = useMemo(() => getMultiTenentMeta(orgName, "index"), [orgName]);

  return (
    <Layout
      title={indexPageMetaTagsContent.title}
      description={indexPageMetaTagsContent.description}
      keywords={indexPageMetaTagsContent.keywords}
    >
      <MemoizedHomeContent/>
      <ToastContainer position="bottom-center" autoClose={8000} />
    </Layout>
  );
});

export default OrgProgramTypeHomePage;

import SectionSimplesteps from "../ShopNow/SectionSimplesteps";
import IncreaseEnergy from "../ShopNow/IncreaseEnergy";
import SectionEfficientHome from "../ShopNow/SectionEfficientHome";
import SectionStreamlineYourSolarJorney from "../ShopNow/SectionStreamlineYourSolarJorney";
// import SectionTrustedPartners from "../ShopNow/SectionTrustedPartners";
import SectionCustomerprefer from "../ShopNow/SectionCustomerprefer";
import SectionTestimonials from "../ShopNow/SectionTestimonials";
import SectionHomeFAQs from "../ShopNow/SectionHomeFAQs";
import SectionGetEstimateNow from "../ShopNow/SectionGetEstimateNow";
import Footer from "../Footer";
import SlideBanner from "./SlideBanner";
import TrustedPartner from "../Common/TrustedPartner";

const index = () => {
  return (
    <>
      <SlideBanner />
      <div className="bg-of-increaseEnergy-and-simpleeSteps">
        <SectionSimplesteps />
        <IncreaseEnergy />
      </div>
      <SectionEfficientHome />
      <SectionStreamlineYourSolarJorney />
      {/* <SectionTrustedPartners /> */}
      <TrustedPartner />
      <SectionCustomerprefer />
      <SectionTestimonials />
      <SectionHomeFAQs />
      <SectionGetEstimateNow />
      <Footer />
    </>
  );
};
export default index;
